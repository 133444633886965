import { template as template_ec8cd62319114ae3860e50bce323f201 } from "@ember/template-compiler";
const FKLabel = template_ec8cd62319114ae3860e50bce323f201(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
